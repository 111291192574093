<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem @click="generarCorte">
        <CIcon icon="cil-dollar" /> Realizar Corte
      </CDropdownItem>
      <CDropdownItem @click="cerrarSession">
        <CIcon icon="cil-lock-locked" /> Cerrar Sesión
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>

  <CModal backdrop="static" alignment="center" :visible="visibleModalCorteCaja"
    @close="() => { visibleModalCorteCaja = false }">
    <CModalHeader>
      <CModalTitle>Corte de caja</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <h5>Resumen del turno</h5>
      <CListGroup flush>
        <CListGroupItem><strong>Total:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else>${{ separatorNumber(movimientosDetalle.total.toFixed(2)) }}</span>
        </CListGroupItem>
        <CListGroupItem><strong>Total Efectivo pesos:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else>${{ separatorNumber(movimientosDetalle.totalEfectivoPesos.toFixed(2)) }}</span>
        </CListGroupItem>
        <CListGroupItem v-if="isDolares"><strong>Total Efectivo Dolares:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else>${{ separatorNumber(movimientosDetalle.totalEfectivoDolares.toFixed(2)) }}</span>
        </CListGroupItem>
        <CListGroupItem><strong>Pagos con tarjeta:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else>${{ separatorNumber(movimientosDetalle.totalTarjetas.toFixed(2)) }}</span>
        </CListGroupItem>
        <hr>
        <CListGroupItem><strong>Base pesos:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else>${{ separatorNumber(movimientosDetalle.basePesos.toFixed(2)) }}</span>
        </CListGroupItem>
        <CListGroupItem v-if="isDolares"><strong>Base dolares:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else> ${{ separatorNumber(movimientosDetalle.baseDolares.toFixed(2)) }} </span>
        </CListGroupItem>
        <CListGroupItem><strong>Total Efectivo + Base pesos:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else> ${{ separatorNumber(movimientosDetalle.totalCajaBasePesos) }}</span>
        </CListGroupItem>
        <CListGroupItem v-if="isDolares"><strong>Total Efectivo + Base dolares:</strong>
          <CPlaceholder animation="wave" v-if="populating">
            <CPlaceholder :xs="3" color="secondary" />
          </CPlaceholder>
          <span v-else>${{ separatorNumber(movimientosDetalle.totalCajaDolares) }}</span>
        </CListGroupItem>
      </CListGroup>
      <hr>
      <CCol md="12" class="mt-2">
        <div class="d-grid gap-2">
          <CButton color="primary" @click="cerrarCaja">Cerrar Caja</CButton>
        </div>
      </CCol>
    </CModalBody>
  </CModal>

  <CModal backdrop="static" alignment="center" :visible="visibleModalAceptar"
    @close="() => { visibleModalCorteCaja = false }">
    <CModalHeader>
      <CModalTitle>Confirmacion Caja</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <h5 class="text-center mb-4">{{ messageTextoSaldoFavor }}</h5>

      <CCol md="12" class="mt-2">
        <div class="d-grid gap-2">
          <CButton color="primary" @click="cierreTurno" :disabled="disbaled">
            <LoadingButton v-if="populating" />
            <span v-else>Confirmar</span>
          </CButton>
        </div>
      </CCol>
    </CModalBody>
  </CModal>
</template>

<script>
import avatar from '@/assets/images/avatars/default.png'
import { removeToken } from "@/core/services/auth/authServices"
import { ObtenerMovimientosDetalle, corteCaja } from './services/servicesPos'
import { createBase } from '@/modules/pos/services/servicesPos'
import { useToast } from "vue-toastification"
import { useDolares } from "@/stores/getDolares"
import LoadingButton from "@/core/components/loading/LoadingButton.vue";
import { consultaBaseCote, crearTicketCortes } from '@/modules/pos/services/servicesPos'

const toast = useToast();

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar
    }
  },
  components: { LoadingButton },
  data() {
    return {
      visibleModalCorteCaja: false,
      visibleModalAceptar: false,
      movimientosDetalle: {},
      ingresoPesosEfectivo: 0,
      ingresoPesosDolares: 0,
      messageTextoSaldoFavor: "",
      messageTextoSaldoCuenta: "",
      messageTextosaldoFaltante: "",
      idTerminal: 0,
      populating: false,
      disbaled: false,
      base: {},
      isFechaInicio: false,
      idSucursal: 0
    }
  },
  created() {
    this.getBase();
  },
  methods: {
    separatorNumber(number) {
            let str = number.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
    },
    async cierreTurno() {
      this.populating = true;
      this.disbaled = true;
      try {
        let base = this.base
        const corteCajaData =
        {
          "id_corte": Number(base.idCorte),
          "pesos_ingresados_caja": this.movimientosDetalle.totalEfectivoPesos,
          "dolares_ingresados_caja": this.movimientosDetalle.totalEfectivoDolares,
          "id_base_pesos": Number(base.idbasePesos),
          "id_base_dolares": Number(base.idbaseDolares),
          "id_usuario": Number(localStorage.getItem('idUsuario')),
          "id_reserva": Number(base.idreserva)
        }

        const response = await corteCaja(corteCajaData);

        await createBase(JSON.stringify({
          "montoPesos": parseFloat(this.movimientosDetalle.basePesos),
          "montoDolares": parseFloat(this.movimientosDetalle.baseDolares),
          "id_usuario": Number(localStorage.getItem('idUsuario')),
          "id_tipo_cambio": 0,
          "id_terminal": this.idTerminal
        }));

        if (response.success) {
          await crearTicketCortes(JSON.stringify({
            id_corte: Number(base.idCorte),
            id_sucursal: this.idSucursal,
            id_terminal: this.idTerminal
          }))

          this.cerrarSession();
        }
      } catch (e) {
        toast.error(e.message);
        this.populating = false;
        this.disbaled = false;
      }
    },
    cerrarSession() {
      removeToken();
      localStorage.removeItem('username');
      localStorage.removeItem('idUsuario');
      localStorage.removeItem('proyectos');
      localStorage.removeItem('nameSucursal');
      localStorage.removeItem('razonSocial');
      localStorage.removeItem('idEmpresa');
      localStorage.removeItem('empresaUsuario');
      localStorage.removeItem('empresas');
      localStorage.removeItem('sucursales');
      localStorage.removeItem('terminal');
      localStorage.removeItem('tipoUsuario');
      localStorage.removeItem('tokenEL');
      localStorage.removeItem('timeToken');
      localStorage.removeItem('tokenAPI');
      localStorage.removeItem('logoEmpresa');
      localStorage.removeItem('isFechaInicio');
      localStorage.removeItem('almacen');
      return this.$router.push({
        name: "Login",
      });
    },
    async generarCorte() {
      let base = this.base
      if (!base) {
        this.cerrarSession();
      } else {
        this.populating = true;
        try {
          await this.getBase()
          if (this.isFechaInicio) {
            this.visibleModalCorteCaja = true;
            const response = await ObtenerMovimientosDetalle(Number(base.idCorte));
            if (response.success) {
              this.movimientosDetalle.total = response.data.total;
              this.movimientosDetalle.totalEfectivoPesos = response.data.totalEfectivoPesos;
              this.movimientosDetalle.totalEfectivoDolares = response.data.totalEfectivoDolares;
              this.movimientosDetalle.totalTarjetas = response.data.totalTarjetas;
              this.movimientosDetalle.basePesos = base.saldoBasePesos;
              this.movimientosDetalle.baseDolares = base.saldoBaseDolares;

              let totalCajaBasePesos = response.data.totalEfectivoPesos + base.saldoBasePesos;
              this.movimientosDetalle.totalCajaBasePesos = totalCajaBasePesos.toFixed(2);

              let totalCajaDolares = response.data.totalEfectivoDolares + base.saldoBaseDolares;
              this.movimientosDetalle.totalCajaDolares = totalCajaDolares.toFixed(2);
            }
          } else {
            toast.error('No se podrá cerrar corte hasta que se inicie el corte');
            this.visibleModalCorteCaja = false;
          }
        } catch (e) {
          toast.error(e.message);
        } finally {
          this.populating = false;
        }
      }
    },
    cerrarCaja() {
      this.saldoFavorText(this.movimientosDetalle.totalEfectivoPesos, this.movimientosDetalle.totalEfectivoDolares);
      this.visibleModalCorteCaja = false;
      this.visibleModalAceptar = true;
    },
    saldoFavorText(totalPesos, totalDolares) {
      let pesosTotal = totalPesos > 0;
      let dolaresTotal = totalDolares > 0;
      if (pesosTotal && dolaresTotal) {
        this.messageTextoSaldoFavor = `Se creará un nuevo movimiento de entrada en la reserva. Deberá depositar en la reserva: 
         $${this.separatorNumber(this.movimientosDetalle.totalEfectivoPesos)} MXN y 
         $${this.separatorNumber(this.movimientosDetalle.totalEfectivoDolares)}. USD.`;
      } else if (pesosTotal) {
        this.messageTextoSaldoFavor = `Se creará un nuevo movimiento de entrada en la reserva. Deberá depositar en la reserva: $${this.separatorNumber(this.movimientosDetalle.totalEfectivoPesos)} MXN.`;
      } else if (dolaresTotal) {
        this.messageTextoSaldoFavor = `Se creará un nuevo movimiento de entrada en la reserva.  Deberá depositar en la reserva: $${this.separatorNumber(this.movimientosDetalle.totalEfectivoDolares)} USD.`;
      } else {
        this.messageTextoSaldoFavor = 'No se creará ningun movimiento en la entrada de la reserva';
      }
    },
    async getBase() {
      try {
        const terminal = localStorage.getItem('terminal');
        const responseBaseCorte = await consultaBaseCote(Number(terminal));
        if (responseBaseCorte.success) {
          this.isFechaInicio = responseBaseCorte.data.fecha_inicio ? true : false
          this.idTerminal = terminal
          this.idSucursal = responseBaseCorte.data.idSucursal
          this.base = responseBaseCorte.data
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
  },
  computed: {
    isDolares() {
      const dolar = useDolares();
      return dolar.dolar;
    }
  },
}
</script>
<style scoped>
.center-check {
  margin-left: 100px;
}
</style>