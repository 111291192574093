import { get, pos, put, getTokenAPIPos } from "@/core/services/servicesPos";
import axios from 'axios'; 

export async function getRoles() {
    let  response = await get('/roles/obtenerRoles');
    return response;
}

export async function saveUsuarioPos(usuario)  {
     let response = await pos('/usuariosPos/altaUsuario', usuario);
     return response;
}

export async function getUsuarios() {
    let response =  await get('/usuariosPos/obtenerUsuarios');
    return response;
}

export async function getUsuariosAdmin() {
    let response =  await get('/usuariosPos/obtenerUsuariosAdmin');
    return response;
}

export async function asignarEmpresasUsuario() {
   let response = await pos('/usuariosPos/asignacionUsuarioEmpresas')
   return response
}

export async function getUsuaiosFilter(sucursales) {
    let response = await pos('/usuariosPos/obtenerUsuariosFilter', sucursales)
    return response
}

export async function updateUsuarioRol(idUsuario, rol) {
    let response = await put(`/usuariosPos/actualizarRolUsuario/${idUsuario}`, rol);
    return response;
}

export async function actulizarStatusUsuario(idUsuario, statusUsuario) {
    let response = await put(`/usuariosPos/actualizarStatusUsuarios/${idUsuario}`, statusUsuario); 
    return response;
}

export async function getUsuario(idUsuario) {
    let response = await get(`/usuariosPos/obtenerUsuario/${idUsuario}`); 
    return response;
}

export async function obtenerTerminalesUsuario(idUsuario){
    let response = await get(`/usuariosPOS/usuario/${idUsuario}/terminales/activo`);
    return response;
}

export async function getUsuarioSinTerminal(idSucursal) {
    let response = await get(`/usuariosPos/obtenerUsuariosSinAsignacion/${idSucursal}`); 
    return response;
}

export async function getUsuarioSucursales(filterSucursales) {
    let response = await pos('/usuariosPos/usuarioSucursales', filterSucursales)
    return response
}

/* conexion para la creacion de reportes */
export async function getCreacionReporte(fechaInicio,fechaFin,propinas,idCanal,idBd) {
    let token = await getTokenAPIPos();
    const headers =  {
        headers: { 
        'Authorization': `Bearer ${token}`,
        'responseType': 'application/vnd.ms-excel',
        'content-type': 'application/vnd.ms-excel;charset=UTF-8',
        }
    }
    await axios.get(`https://herramientasapi.gdar.mx/generico/generaReporteVentasPOS?idBD=${idBd}&idCanal=${idCanal}&fechaInicio=${fechaInicio}&fechaFinal=${fechaFin}&propinas=${propinas}`,{responseType: 'blob'},headers)
    .then(response => {
          const link = document.createElement("a"); 
          link.href = URL.createObjectURL(response.data);
          link.download = `ReporteVentasPOS_`+fechaInicio+`_al_`+fechaFin+`.xlsx`;
          link.click();
      })
      .catch(error => {
        console.error('Error al descargar el archivo Excel:', error);
      });
}

/* llamada para obtener las sucursales del usuario de elsa */
export async function getSucursalesUsuarios(idUsuario) {
    let response = await get(`/sucursales/obtenerSucursalesUsuario/${idUsuario}`);
    return response;
}