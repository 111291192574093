<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <template v-if="tipoUsuario != 4">
            <template v-if="logo">
              <img :src="logo" class="logo-empresa-header" alt="logoEmpresa">
            </template>
            <span><b>Usuario {{ username }} </b></span>
          </template>
          <template v-else>
            Usuario configuración
          </template>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <template v-if="tipoUsuario != 4">
      <CHeaderDivider />
      <CContainer fluid>
        {{ sucursal }} {{ terminal }}
      </CContainer>
    </template>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { obternerTerminalesAsignadas } from '@/modules/terminales/services/servicesPos'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  props: {
    sucursal: {
      type: String,
      default: ''
    },
    empresa: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tipoUsuario: 0,
      terminal: '',
      logo: ''
    }
  },
  async created() {
    this.tipoUsuario = localStorage.getItem('tipoUsuario')
    let terminal = localStorage.getItem('terminal');
    const responseTerminales = await obternerTerminalesAsignadas(terminal)
    if (responseTerminales.success) {
      this.terminal = `- Terminal ${responseTerminales.data[0].descripcion}`
    }
    this.getLogo()
  },
  methods: {
    getLogo() {
      let storedValue = localStorage.getItem('logoEmpresa')
      if (storedValue === null || storedValue === undefined || storedValue === '') {
        this.logo = ''
      } else {
        this.logo = 'https://cdn.gdar.com.mx/archivos/' + localStorage.getItem('logoEmpresa')
      }
    }
  },
}
</script>
